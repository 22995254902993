.page-container {
    margin-top: 48px;
    color: #0E0E0E;
    padding-bottom:100px;

    .breadcrumb {
        border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
        background-color: transparent;
        border-radius: 0;
        margin: 0;
        padding: 13px 0 13px 0;
        height: 52px;

        a.back {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-10 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='32' height='32' rx='3'/%3E%3Cpath d='M19.713 22.99v.212l-.707.707h-.213l-7.9-7.9v-.216l7.9-7.9h.213l.707.708v.212l-7.089 7.089 7.089 7.088zm-8.82-7.192l.003-.002.106.106-.106.106-.002-.002v-.208zm.215.205l-.106-.101.104-.104.002.205z' fill='rgb(14,14,14)'/%3E%3C/g%3E%3C/svg%3E");
            width: 10px;
            height: 17px;
            display: inline-block;
            position: absolute;
            opacity: 0.5;
            margin-left: 15px;
            margin-top: 3px;
        }

        h1 {
            font-size: 17px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            margin: 0;
            padding: 0;
            width: 100%;
        }
    }

    .page-content {
        padding: 11.5px 15px;

        h2 {
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
        }

        p {
            font-size: 15px;
            line-height: 20px;
        }
    }


    ul.arrow-links {
    margin:0;
    padding:0;

    li {
        list-style: none;
        padding: 0 0 0 15px;
        margin: 0;

        a {
            width: calc(100% - 30px);
            color: #0E0E0E;
            display: inline-block;
            border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
            padding: 12px 0 12px 0px;
            margin: 0 auto;
            font-size: 15px;
            line-height: 20px;
            position: relative;

            &::after {
                content: '';
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.894 11.99v.212l.707.707h.212l5.9-5.9v-.216l-5.9-5.9h-.212l-.707.708v.212l5.088 5.089L.894 11.99zm6.819-5.192l-.002-.002-.107.106.107.106.002-.002v-.208zm-.215.205l.106-.101-.103-.104-.003.205z' fill='%23868686' fill-rule='evenodd'/%3E%3C/svg%3E");
                width: 8px;
                height: 13px;
                position: absolute;
                right: 0;
                margin-top: 1px;
            }
        }
    }
}


}