.order-container {
    h1 {
        color: #0E0E0E;	
        font-size: 17px;	
        font-weight: 600;	
        line-height: 24px;
        text-align: center;
        padding: 13px 0 14px 0;
        border-bottom: 1px solid rgba(14,14,14,0.1);
        margin-top: 48px;
    }

    #list {
        padding: 0;
        margin: 0;
    }

    .no-orders {
        text-align: center;
        padding-top: 168px;

        p {
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            
            span {
                color: rgba(14,14,14,0.5);
                font-weight: normal;
            }
        }

        .button {
            background-color: #20B4E4;
            display: inline-block;
            color: #ffffff;
            height: 48px;
            width: 193px;
            font-size: 15px;	
            font-weight: 600;
            line-height: 48px;
            margin-bottom: 196px;

            &:hover {
                text-decoration: none;
            }
        }

        .popular {
            .products {
                width: 100%;
                margin-top: 0;

                .product {
                    width: 123px;
                    
                    &:nth-child(3n+2),
                    &:nth-child(3n+1) {
                        margin-right: 1px !important;
                    }

                    &:nth-child(3n+2) {
                        width: 125px;
                    }

                    .img {
                        width: 123px;
                        height: 123px;
                    }
                }
            }
        }
    }

    .orders {

        .order {
            text-align: left;
            border-bottom: 1px solid rgba(14,14,14,0.1);
            position: relative;
            padding: 0;
            margin: 0 15px 10px 15px;
            width: calc(100% -  30px);

            &:after {
                content: '';
                height: 12.02px;
                width: 6.82px;
                position: absolute;;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.894 11.99v.212l.707.707h.212l5.9-5.9v-.216l-5.9-5.9h-.212l-.707.708v.212l5.088 5.089L.894 11.99zm6.819-5.192l-.002-.002-.107.106.107.106.002-.002v-.208zm-.215.205l.106-.101-.103-.104-.003.205z' fill='%23868686' fill-rule='evenodd'/%3E%3C/svg%3E");
                right: 0;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                z-index: -1;
            }

            .img-container {
                width: 56px;
                float: left;
                height: 100%;

                .img {
                    width: 56px;
                    height: 56px;
                    background: #ccc;

                    img
                    {
                        object-fit:cover;
                        width:100%;
                    }
                }
            }

            .details {
                width: calc(100% - 72px);
                float: right;
                padding-right: 30px;

                ul {
                    color: #B4B4B4;	
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 10px;

                    li {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
            
            h1 {
                color: #0E0E0E;	
                font-size: 15px;
                line-height: 20px;
                padding: 0;
                margin: 0;
                font-weight: normal;
                text-align: left;
                border-bottom: 0;
            }
        }
    }

    &.single-order {
        .back {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-10 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='32' height='32' rx='3'/%3E%3Cpath d='M19.713 22.99v.212l-.707.707h-.213l-7.9-7.9v-.216l7.9-7.9h.213l.707.708v.212l-7.089 7.089 7.089 7.088zm-8.82-7.192l.003-.002.106.106-.106.106-.002-.002v-.208zm.215.205l-.106-.101.104-.104.002.205z' fill='rgb(14,14,14)'/%3E%3C/g%3E%3C/svg%3E");
                width: 10px;
                height: 17px;
                display: inline-block;
                position: absolute;
                opacity: 0.5;
                left: 0;
                margin-top: 4px;

                &:hover {
                    opacity: 0.75;
                }

                &:active {
                    opacity: 0.75;                    
                }
        }

        .img {
            width: 112px;
            height: 112px;
            float: left;
            background: #ccc;

            img
            {
                width:100%;
                object-fit:cover;
            }
        }

        .details {
            float: right;
            width: calc(100% - 127px);
            margin-bottom: 28px;

            h1 {
                margin: 0;
                padding: 0;
                border-bottom: 0;
                text-align: left;
                color: #0E0E0E;
                font-size: 20px;
                line-height: 24px;
                font-weight: normal;
            }

            .price {
                margin: 4px 0 8px 0;
                font-size: 15px;
                display: inline-block;
            }

            ul {
                padding: 0;
                list-style: none;

                li {
                    color: #B4B4B4;	
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        .details-ul {
            padding: 0;
            list-style: none;
            margin: 0 15px;
            
                li {
                    color: #B4B4B4;	
                    font-size: 15px;
                    line-height: 20px;
                    padding-top: 12px;

                    &.line {
                        padding-bottom: 12px;
                        border-bottom: 1px solid rgba(14,14,14,0.1);
                    }

                    .desc {
                        color: rgba(14,14,14,0.5);
                        float: left;
                        width: 50%;
                        display: inline-block;

                        &.dark {
                            color: #0E0E0E;
                        }
    
                        &.light {
                            color: rgba(14,14,14,0.5);
                        }
                    }

                    .val {
                        color: #0E0E0E;
                        float: right;
                        width: 50%;
                        display: inline-block;

                        &.dark {
                            color: #0E0E0E;
                        }
    
                        &.light {
                            color: rgba(14,14,14,0.5);
                        }
                    }
                }
        }
    }
}

.thick-hr {
    background-color: #FAFAFA;
    width: 375px;
    height: 5px;
    margin: 20px 0 12px 0;
}

.clearfix {
    clear: both;
}