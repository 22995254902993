.page-categories {

    padding-bottom:120px;

    .categories {
        padding: 0 15px 40px 15px;
        margin-top: 60px;

        .title {
            color: #0E0E0E;
            font-size: 17px;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 12px;
        }

        ul {
            padding-left: 0;
            list-style: none;

            li {
                float: left;
                margin-bottom: 9px;
                width: 164px;

                &:nth-child(2n + 1) {
                    margin-right: 16px;
                }

                a {
                border: 0.5px solid rgba(14,14,14,0.1);
                border-radius: 3px;
                display: inline-block;
                color: #0E0E0E;
                overflow: hidden;

                span {
                    display: inline-block;
                    padding: 8px 10px;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    min-height:60px;
                }

               &:hover {
                    color: #20B4E4;
/* 
                    .image {
                        &:before {
                            display: inline-block;
                            background: rgba(14,14,14,0.1);
                        }
                    }*/
                }

                &:active {
 /*                   color: #137B9D;

                    .image {
                        &:before {
                            display: inline-block;
                            background: rgba(14,14,14,0.3);
                        }
                    } */
                }

                    .image {
                        height: 104px;
                        width: 165px;
                        position: relative;

                        &:before {
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                        }

                        img {
                            object-fit: cover;
                            height: 104px;
                            width: 165px;
                        }
                    }

                }
            }
        }
    }

    .clear {
        clear: both;
    }
}