.products .bookmark {
    width: 15px;
    float: right;

    &:active
    {
       &:before
       {
           content: "";
           background: #DFDFDF;
           border-radius:4px;
           width:27px;
           height:26px;
           position:absolute;
           top:0px;
           left:-5px;
           z-index:-1;
       }
    }

}

.product-container .options .bookmark
{
    width:32px;
    height:32px;
}

.product-container .related .bookmark
{
    padding:0px;
    left:13px;
}