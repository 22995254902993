.spinner {
    -webkit-animation: load 1s infinite linear;
    -moz-animation: load 1s infinite linear;
    animation: load 1s infinite linear;
    background: -webkit-linear-gradient(top, #b6b6b6 10%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to bottom, #b6b6b6 10%, rgba(255, 255, 255, 0) 100%);
    height: 2em;
    width: 2em;
    border-radius: 50%;
    font-size: 18px;
    margin: 5em auto;
    position: relative;
  }
  .spinner:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 50%, #b6b6b6 50%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #b6b6b6 50%);
    border-radius: 100%;
    content: '';
  }
  .spinner:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 90%;
    width: 90%;
    background: #fff;
    border-radius: 50%;
    content: '';
    margin: auto;
  }
  
  @-webkit-keyframes load {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes load {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }
  @keyframes load {
    from {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  