

.page-thankyou {
    position: relative;

.confirmation {
    border-radius: 16px 16px 0 0;
    background-color: #F9F9F9;
    display: block;
    width: 375px;
    z-index: 3;
    position: fixed;
    bottom: -30px;
    left: 0;
    transition-property: all;
    transition-duration: .28s;
    transition-timing-function: ease-in-out;
    overflow-y: hidden;
    left: 50%;
    margin-left: -188px; /* Half of the width */
    max-height: 550px;
    height: 5500px;
    //top: 36px;
    z-index: 5;

    &:before {
        content: '';
        height: 5px;
        width: 56px;
        border-radius: 100px;
        background-color: rgba(14,14,14,0.1);
        position: absolute;
        top: 16px;
        left: calc(50% - 28px);
    }

    .hr {
        background-color: rgba(14,14,14,0.1);
        width: 100%;
        height: 1px;
        margin-top: 48px;
        
    }

}

.message {
    text-align: center;
    width: 315px;
    margin: 18px auto 83px auto;

    h1 {
        color: #0E0E0E;
        font-size: 26px;
        line-height: 32px;
    }

    p {
        color: rgba(14,14,14,0.5);
        font-size: 15px;	
        line-height: 20px;
    }
}

.product {
    float: left;
    padding: 0 !important;
    margin-bottom: 2px;
    height: 123px !important;

    img {
        object-fit: cover;
        height: 123px;
        width: 123px;
    }
}

.products {

    .product {
        .img {
            object-fit: cover;
            height: 123px;
            width: 123px;
        }
    }

    div:nth-child(3n+2) {
        
        padding-right: 2px !important;
        img {
            width: 125px;
        }
    }

    div:nth-child(3n+3) {
       padding-left: 2px !important;
       img {
            width: 123px;
       }
    }


}

.checkmark {
    text-align: center;
    
    .circle {
        border-radius: 50%;
        border: 2px solid #49DC40;
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-top: 40px;
    }

    .check {
        display: inline-block;
        transform: rotate(37deg);
        height: 24px;
        width: 12px;
        border-bottom: 3px solid #49DC40;
        border-right: 3px solid #49DC40;
        margin: 14px auto;
    }
}

}