#root {
    overscroll-behavior-y: contain;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  
body
{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    overflow-y:scroll;
    overscroll-behavior-y: contain;

    &.hidden
    {
        height:100%;
        overflow:hidden;
    }

}

.app {
    max-width: 375px;
    margin:0 auto;
}

#launcher {
    bottom: 90px !important;
    margin-right: 15px;
}

a.blue
{
    text-decoration: none;
            color: #20B4E4;
            font-size: 15px;	
            line-height: 20px;

            display: inline-block;

            &:hover {
                color: #20B4E4;
            }

            &:active {
                color: #137B9D;
            }
}
