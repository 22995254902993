.settings-container {
    margin-top: 48px;
    padding-bottom: 100px;

    h1 {
        color: #0E0E0E;	
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;	
        text-align: center;
        padding-top: 13px;
        padding-bottom: 14px;
        border-bottom: 0.5px solid rgba(14,14,14,0.1);
        margin-bottom: 0;
    }


    .settings-header {
        border-bottom: 0.5px solid rgba(14,14,14,0.1);

        h1 {
            border-bottom: 0;
        }

        a, button {
            color: #3897F0;
            margin-top: 13px;
            border: 0;
            background: transparent;
            font-size: 17px;
        }

        button {
            float: right;
            text-align: right;
        }

        margin-left: auto;
        margin-right: auto;
       // border-bottom: 1px solid rgba(14,14,14,0.1);

       .save {
           padding-right: 0;
           padding-top: 0;
       }

       .continue {
        top: 12px;
        position: relative;
       }

    }

    .product-container {
        &.text {
            padding: 0 15px;
            color: #B4B4B4;
            font-size: 12px;
            line-height: 14px;
            margin-top: 27.5px;  

            span {
                color: #3897F0;
            }
        }

        ul { 
            li { 
                a { 
                    width: 100%; 
                }
            }
        }
    }

    .profile-container {
        margin: 0;

        .photo {
            width: 104px;
            height: 104px;
            border-radius: 50%;
            background: #cccccc;
            margin: 32px auto;

            img {
                border-radius: 50%;
                height: 104px;
                width: 104px;
            }
        }
        form {
            width: 100%;
        }
    }

    ul.profile-ul {
        list-style: none;
        padding-left: 0;
        border-bottom: 0;
        border-top: 0.5px solid rgba(14,14,14,0.1);
        padding-top: 1px;

        div {
            display: inline-block;
        }

        li {
            padding-left:  15px;

            h2 {
                width: 96px;
                color: #0E0E0E;
                font-size: 15px;
                line-height: 20px;
                display: inline-block;
                padding: 12px 0;
                font-weight: normal;
            }
            
            input {
                border: 0;
                border-bottom: 0.5px solid rgba(14,14,14,0.1);
                width: 247px;
                float: right;
                margin-right: 15px;
                height: 48px;
                font-size: 15px;
                padding-left: 0;

                &:focus {
                    outline: 0;
                }
            }

            a {
                width: 247px;
                color: #0E0E0E;
                display: inline-block;
                border-bottom: 0.5px solid rgba(14,14,14,0.1);
                padding: 12px 0 12px 0;
                font-size: 15px;
                margin-right: 15px;
                float: right;
                line-height: 23px;
                background-repeat: no-repeat;
                background-size: 18px 18px;
                background-position: left center;
                margin-top: 0 !important;
                height: 48px;
                
                &:hover {
                    text-decoration: none;
                }

                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.894 11.99v.212l.707.707h.212l5.9-5.9v-.216l-5.9-5.9h-.212l-.707.708v.212l5.088 5.089L.894 11.99zm6.819-5.192l-.002-.002-.107.106.107.106.002-.002v-.208zm-.215.205l.106-.101-.103-.104-.003.205z' fill='%23868686' fill-rule='evenodd'/%3E%3C/svg%3E");
                    width: 8px;
                    height: 13px;
                    position: absolute;
                    right: 15px;
                    margin-top: 5px;
                }
            }
        }
    }

    ul.settings-ul {

        list-style: none;
        padding-left: 0;
        margin: 0 15px;

        li {
            a {
                color: #0E0E0E;
                display: inline-block;
                border-bottom: 0.5px solid rgba(14,14,14,0.1);
                width: 100%;
                padding: 12px 0 12px 30px;
                font-size: 15px;
                line-height: 20px;
                background-repeat: no-repeat;
                background-size: 18px 18px;
                background-position: left center;

                &:hover {
                    text-decoration: none;
                }

                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.894 11.99v.212l.707.707h.212l5.9-5.9v-.216l-5.9-5.9h-.212l-.707.708v.212l5.088 5.089L.894 11.99zm6.819-5.192l-.002-.002-.107.106.107.106.002-.002v-.208zm-.215.205l.106-.101-.103-.104-.003.205z' fill='%230E0E0E' fill-rule='evenodd'/%3E%3C/svg%3E");
                    width: 8px;
                    height: 13px;
                    position: absolute;
                    right: 15px;
                    margin-top: 1px;
                }

                &.profile {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='26' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M-3-3h32v32H-3z'/%3E%3Cpath d='M13 13.15a6.15 6.15 0 1 1 0-12.3 6.15 6.15 0 0 1 0 12.3zm0-1.3a4.85 4.85 0 1 0 0-9.7 4.85 4.85 0 0 0 0 9.7zM25.15 25l-.15.15h-1l-.15-.15v-3A4.85 4.85 0 0 0 19 17.15H7A4.85 4.85 0 0 0 2.15 22v3l-.15.15H1L.85 25v-3A6.15 6.15 0 0 1 7 15.85h12A6.15 6.15 0 0 1 25.15 22v3z' fill='%230E0E0E'/%3E%3C/g%3E%3C/svg%3E");
                }

                &.privacy {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='18' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M-3 0h24v24H-3z'/%3E%3Cg stroke='%230E0E0E'%3E%3Crect x='.5' y='9.5' width='17' height='13' rx='3'/%3E%3Cpath d='M4.5 9.5h9V5a4.5 4.5 0 1 0-9 0v4.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    background-size: 18px 23px;
                }

                &.terms {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M-4-3h24v24H-4z'/%3E%3Cpath d='M3 0h10a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3z' fill='%230E0E0E' fill-rule='nonzero'/%3E%3Cpath d='M3.5 4h9a.5.5 0 1 1 0 1h-9a.5.5 0 0 1 0-1zm0 1a.5.5 0 0 0 0-1h9a.5.5 0 1 0 0 1h-9zM3.5 7h7a.5.5 0 1 1 0 1h-7a.5.5 0 1 1 0-1zm0 1a.5.5 0 1 0 0-1h7a.5.5 0 1 0 0 1h-7zM3.5 10h9a.5.5 0 1 1 0 1h-9a.5.5 0 1 1 0-1zm0 1a.5.5 0 1 0 0-1h9a.5.5 0 1 0 0 1h-9zM3.5 13h4a.5.5 0 1 1 0 1h-4a.5.5 0 1 1 0-1zm0 1a.5.5 0 1 0 0-1h4a.5.5 0 1 0 0 1h-4z' fill='%230E0E0E' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E");
                    background-size: 16px 18px;
                }
            }
        }
    }


    .logout {
        padding: 0 15px;
        margin: 56px 0 0 0;
        display: inline-block;
        color: #ED2929;

        &:hover {
            text-decoration: none;
        }
    }
}

h1.h1-header {

        color: #0E0E0E;	
        font-size: 17px;	
        font-weight: 600;	
        line-height: 24px;
        text-align: center;
        padding: 13px 0 14px 0;
        border-bottom: 0.5px solid rgba(14,14,14,0.1);
        margin-top: 48px;
        margin-bottom: 27px;

        .back {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='10' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-10 -7)' fill='none' fill-rule='evenodd'%3E%3Crect fill='none' width='32' height='32' rx='3'/%3E%3Cpath d='M19.713 22.99v.212l-.707.707h-.213l-7.9-7.9v-.216l7.9-7.9h.213l.707.708v.212l-7.089 7.089 7.089 7.088zm-8.82-7.192l.003-.002.106.106-.106.106-.002-.002v-.208zm.215.205l-.106-.101.104-.104.002.205z' fill='rgb(14,14,14)'/%3E%3C/g%3E%3C/svg%3E");
            width: 10px;
            height: 17px;
            display: inline-block;
            position: absolute;
            opacity: 0.5;
            left: 15px;
            margin-top: 4px;

            &:hover {
                opacity: 0.75;
            }

            &:active {
                opacity: 0.75;                    
            }

        }

        .cancel {
            display: inline-block;
            position: absolute;
            left: 15px;
          //  margin-top: 2px;
            font-size: 17px;
            line-height: 24px;
            font-weight: normal;
        }

}

.profile-edit {
    .form-control {
        border: 0;
        border-bottom: 0.5px solid rgba(14,14,14,0.1);
        width: calc(100% - 30px);
        margin: 0 15px;
        padding-left: 0;
        border-radius: 0;
        font-size: 15px;
        line-height: 20px;

        &:focus {
            outline: 0;
            border-bottom: 0.5px solid rgba(14,14,14,0.1);
        }

        &::placeholder {
           color: rgba(14,14,14,0.3);
           font-size: 15px;	line-height: 20px;
        }
    }

    .form-group {
       &.intl-tel-input {
           .form-control {
               width: 281px;
               float: right;
               margin-right: 15px;
               padding-left: 0;
           }
       }

       .selected-flag {
           margin-left: 15px;
           box-shadow: inset 0 -0.5px 0 0 rgba(14,14,14,0.1);
       }
    }

    .btn-primary.btn {
        color: #3897F0;
        font-size: 17px;	
        line-height: 24px;	
        text-align: right;
        background: transparent;
        border: 0;
        position: absolute;
        right: 0;
        top: 7px;
        padding-right: 15px;

        &:active, &:hover, &:focus {
            background: transparent !important;
            outline: 0 !important;   
            box-shadow: none !important;
        }

        &:hover {
           color: #3081CC !important;
        }

        &:active {
            color: #3CA0FF !important;
         }
    }
}

.phone-edit-group {

    .intl-tel-input {
        width: 100%;
    }

    .form-group.intl-tel-input .selected-flag {
        width: 46px;
        padding-left: 0;
        border-bottom: solid 0.5px rgba(14, 14, 14, 0.1);
    }

    .form-group.intl-tel-input .selected-flag .iti-arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icons-&amp;-Guide' fill='none' fillRule='evenodd'%3E%3Cg id='Icons' transform='translate(-186 -61)' fill='%238a8a8a'%3E%3Cg id='icon/list/down' transform='translate(183 55)'%3E%3Cpolygon id='Color' points='14.9901282 6.89393398 15.2022602 6.89393398 15.909367 7.60104076 15.909367 7.8131728 10.009872 13.7126677 9.79342899 13.7126677 3.89393398 7.8131728 3.89393398 7.60104076 4.60104077 6.89393398 4.81317281 6.89393398 9.9016505 11.9824116' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border: 0;
    width: 8px;
    height: 5px;
    background-size: cover;
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 6px;
}

}

.clearfix {
    clear: both;
}