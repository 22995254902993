
.payform
{


fieldset {
    margin-bottom: 20px;
    background: #fff;
   /* box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
      0 4px 6px 0 rgba(112, 157, 199, 0.15); */
  //  border-radius: 4px;
    border: none;
    
  }
  
  fieldset label {
    position: relative;
  //  display: flex;
    flex-direction: row;
 //   height: 42px;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
    color: #8898aa;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.4em;
    width: 100%;
  }
  
  fieldset label:not(:last-child) {
    border-bottom: 1px solid #f0f5fa;

    &.invalid
    {
      border-bottom: 1px solid red;
    }
  }
  
  fieldset label.state {
    display: inline-flex;
    width: 75%;
  }
  
  fieldset:not(.with-state) label.state {
    display: none;
  }
  
  fieldset label.zip {
    display: inline-flex;
    width: 25%;
    padding-right: 60px;
  }
  
  fieldset:not(.with-state) label.zip {
    width: 100%;
  }
  
  fieldset label span {
    min-width: 125px;
    padding: 0 15px;
    text-align: right;
    font-size: 15px;
    line-height: 1.4em;
    display: inline-block;

    width: 100%;
    text-align: left;
    color: #B4B4B4;
    font-size: 12px;
    line-height: 14px;
  }
  
  fieldset .redirect label span {
    width: 100%;
    text-align: center;
  }
  
  p.instruction {
    display: inline-table;
    margin-top: -32px;
    padding: 0 5px;
    text-align: center;
    background: #f8fbfd;
  }
  
  p.tip {
    margin: -10px auto 10px;
    padding: 5px 0 5px 30px;
    font-size: 14px;
    background: url(/images/tip.svg) left center no-repeat;
  }
  
  .field {
    flex: 1;
    padding: 0 15px;
    background: transparent;
    font-weight: 400;
    color: #31325f;
    outline: none;
    cursor: text;
  }
  
  .field::-webkit-input-placeholder {
    color: #aab7c4;
  }
  .field::-moz-placeholder {
    color: #aab7c4;
  }
  .field:-ms-input-placeholder {
    color: #aab7c4;
  }
  
  fieldset .select::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 5px;
    right: 20px;
    top: 50%;
    margin-top: -2px;
    background-image: url(/images/dropdown.svg);
    pointer-events: none;
  }
  
  input {
    flex: 1;
    border-style: none;
    outline: none;
    color: #313b3f;
  }
  
  select {
    flex: 1;
    border-style: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    color: #313b3f;
    cursor: pointer;
    background: transparent;
  }
  
  select:focus {
    color: #666ee8;
  }
  
  ::-webkit-input-placeholder {
    color: #cfd7e0;
  }
  ::-moz-placeholder {
    color: #cfd7e0;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: #cfd7e0;
  }
  
  input:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: #666ee8;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out 1s;
  }
  
  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    padding: 15px 0;
    border-bottom: 1px solid #f0f5fa;
  }
  
  #card-element {
    margin-top: -1px;
  }
  
  #ideal-bank-element {
    padding: 0;
  }
  
  button {
    display: block;
    background: #666ee8;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    //border-radius: 4px;
    border: 0;
    font-weight: 700;
    width: calc(100% - 30px);
    height: 40px;
    outline: none;
    cursor: pointer;
    transition: all 0.15s ease;
    margin: 15px;
    font-weight: 600;	
    line-height: 24px;
    font-size: 18px;
    padding-top: 2px;
  }
  
  button:focus {
    background: #555abf;
  }
  
  button:hover {
    transform: translateY(-1px);
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.08);
  }
  
  button:active {
    background: #43458b;
  }
  
  #country {
    display: flex;
    align-items: center;
  }
  
  #country select {
    margin: 0 -15px 0 -30px;
   // padding: 0 15px 0 30px;
   color: rgba(14,14,14,0.3);
  }
  
  #country::before {
    display: inline-flex;
    content: '';
    width: 21px;
    height: 15px;
    background: url(/images/flags.svg);
    background-position: -1000px -1000px;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
  
  #country.at::before {
    background-position: -165px -10px;
  }
  #country.au::before {
    background-position: -196px -10px;
  }
  #country.be::before {
    background-position: -227px -10px;
  }
  #country.br::before {
    background-position: -351px -10px;
  }
  #country.ca::before {
    background-position: -382px -10px;
  }
  #country.ch::before {
    background-position: -475px -10px;
  }
  #country.cn::before {
    background-position: -41px -35px;
  }
  #country.de::before {
    background-position: -134px -35px;
  }
  #country.dk::before {
    background-position: -196px -35px;
  }
  #country.es::before {
    background-position: -320px -35px;
  }
  #country.eu::before {
    background-position: -351px -35px;
  }
  #country.fi::before {
    background-position: -382px -35px;
  }
  #country.fr::before {
    background-position: -413px -35px;
  }
  #country.gb::before {
    background-position: -475px -35px;
  }
  #country.hk::before {
    background-position: -41px -60px;
  }
  #country.ie::before {
    background-position: -196px -60px;
  }
  #country.it::before {
    background-position: -351px -60px;
  }
  #country.jp::before {
    background-position: -444px -60px;
  }
  #country.lu::before {
    background-position: -258px -85px;
  }
  #country.mx::before {
    background-position: -475px -85px;
  }
  #country.nl::before {
    background-position: -103px -110px;
  }
  #country.no::before {
    background-position: -134px -110px;
  }
  #country.nz::before {
    background-position: -165px -110px;
  }
  #country.pt::before {
    background-position: -413px -110px;
  }
  #country.se::before {
    background-position: -103px -135px;
  }
  #country.sg::before {
    background-position: -134px -135px;
  }
  #country.us::before {
    background-position: -475px -135px;
  }
  
  /* Payment Methods */
  
  #payment-methods {
    margin: 0 0 20px;
    border-bottom: 2px solid #e8e8fb;
  }
  
  #payment-methods li {
    display: none;
  }
  
  #payment-methods li.visible {
    display: inline-block;
    margin: 0 20px 0 0;
    list-style: none;
  }
  
  #payment-methods input {
    display: none;
  }
  
  #payment-methods label {
    display: flex;
    flex: 1;
    cursor: pointer;
  }
  
  #payment-methods input + label {
    position: relative;
    padding: 5px 0;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
  }
  
  #payment-methods label::before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -2px;
    left: 0;
    border-bottom: 2px solid #6772e5;
    opacity: 0;
    transform: scaleX(0);
    transition: all 0.25s ease-in-out;
  }
  
  #payment-methods label:hover {
    color: #6772e5;
    cursor: pointer;
  }
  
  #payment-methods input:checked + label {
    color: #6772e5;
  }
  
  #payment-methods label:hover::before,
  #payment-methods input:checked + label::before {
    opacity: 1;
    transform: scaleX(1);
  }
  
  #payment-methods,
  .payment-info {
    display: none;
  }
  
  .payment-info:not(.card) {
    margin-bottom: 15px;
  }
  
  .payment-info.ideal {
    margin-bottom: 0;
  }
  
  #payment-methods.visible,
  .payment-info.visible {
    display: block;
  }
  
  .payment-info.card.visible,
  .payment-info.sepa_debit.visible {
    text-align: center;
  }
  
  .payment-info p.notice {
    font-size: 14px;
    color: #8898aa;
    text-align: left;
  }
  
  #wechat-qrcode img {
    margin: 0 auto;
  }
  
  .element-errors {
    display: inline-flex;
    height: 20px;
    margin: 15px auto 0;
    padding-left: 20px;
    color: #e25950;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    background: url(/images/error.svg) center left no-repeat;
    background-size: 15px 15px;
  }
  
  .element-errors.visible {
    opacity: 1;
    transform: none;
  }
  
  #iban-errors {
    margin-top: -20px;
  }
  
}

.pay-container {
  z-index: 10;
}

.payment_request {
  width: calc(100% - 100px);
  max-width: 200px;
  background:#000000;
  float: right;
}