body.overlay-open {
    overflow: hidden;
    height:100%;
    max-width:100%;
    overscroll-behavior: none;

    #root
    {
        overflow-y:scroll;
        overscroll-behavior: none;
    }

    @media (max-width: 1024px) {
      position: fixed;
      width: 100%;
    }
}

.overlay {
    width:100%;
    height:100%;
    background:rgba(237,237,237,0.75);
    position:absolute;
    top: -48px;
    left:7.5px;
    z-index:6;
  

    .xycenter {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: calc(100% - 30px);
    max-width: 345px;
    padding: 32px 30px;
    box-shadow: 0 1px 3px 0 rgba(14,14,14,0.15), 0 0 16px 0 rgba(14,14,14,0.1), 0 1px 8px 0 rgba(14,14,14,0.1);

        a {
            text-decoration: none;
            
            &:hover {
                color: #137B9D;
            }

            &:active {
                color: #045772;
            }
        }
        
        h1 {
            color: #0E0E0E;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 26px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 8px;
            font-weight: normal;
        }

        .prompt {
            color: rgba(14,14,14,0.5);
            font-size: 15px;
            line-height: 20px;	
            text-align: center;
            margin-bottom: 0;
        }

        .right {
            text-align: right;   
            margin-bottom: 8px;             
        }

        .center {
            text-align: center;
            margin: 0;
        }



        .or {
            font-size: 12px;
            color: #B4B4B4;
            margin-top: 40px;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            height: 14px;
            margin-bottom: 40px;

            &:after {
                content: '';
                background: #f2f2f2;
                height: 1px;
                width: 100%;
                position: absolute;
                left: 0;
                top: 8px;

            }
            span {
                background: #fff;
                padding: 0 15px;
                z-index: 1;
                position: relative;
            }
        }

        .forgot {
            text-decoration: none;
            color: #20B4E4;
            font-size: 15px;	
            line-height: 20px;
        	text-align: center;
            margin-top: 24px;
            display: inline-block;

            &:hover {
                color: #20B4E4;
            }

            &:active {
                color: #137B9D;
            }

        }

        .signup {
            color: #20B4E4;
            font-size: 15px;
            font-weight: 600;
            line-height: 20px;
        }

        .inputs {
            border: 1px solid rgba(14,14,14,0.1);
            background-color: rgba(255,255,255,0.02);
          //  box-shadow: inset 0 -0.5px 0 0 rgba(14,14,14,0.1);
            padding: 1px;

            .hr {
                background-color: rgba(255,255,255,0);
                box-shadow: inset 0 -1px 0 0 rgba(14,14,14,0.1), inset 0 0.5px 0 0 rgba(14,14,14,0.1);
                width: 220px;
                height: 1px;
                margin: 0 auto;
            }

            .form-group {
                margin-bottom: 0;
            }
        }

        .form-group {

            input.form-control {

                :-webkit-autofill {
                    border: 0;
                    background: transparent;
                    border-bottom: 0.5px solid rgba(14,14,14,0.1);
                }
            }

            &.signup {
                margin-top: 52px;
                margin-bottom: 0;
                width: 100%;

                input {
                    background-color: rgba(255,255,255,0.02);
                   // box-shadow: inset 0 -0.5px 0 0 rgba(14,14,14,0.1);
                    border-bottom: 0.5px solid rgba(14,14,14,0.1);
                    border-radius: 0;
                    height: 42px;
                    width: 221px;
                    float: right;
                    padding-left: 0 !important;
                }
            }

            &.signin {
                margin-top: 52px;
                margin-bottom: 4px;
                
                input {
                    border-bottom: 0.5px solid rgba(14,14,14,0.1);
                }

                .invalid-feedback {
                    position: absolute;
                    margin-top: -55px;
                    padding-left: 0;
                }
            }

            &.intl-tel-input {
                .selected-flag {
                    //box-shadow: inset 0 -0.5px 0 0 rgba(14, 14, 14, 0.1);
                    border-bottom: 0.5px solid rgba(14,14,14,0.1);
                    padding-left: 0;
                    height: 41px;

                    &:focus {
                        outline: 0;
                    }
                }

                .selected-flag .iti-arrow {   
                    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icons-&amp;-Guide' fill='none' fillRule='evenodd'%3E%3Cg id='Icons' transform='translate(-186 -61)' fill='%238a8a8a'%3E%3Cg id='icon/list/down' transform='translate(183 55)'%3E%3Cpolygon id='Color' points='14.9901282 6.89393398 15.2022602 6.89393398 15.909367 7.60104076 15.909367 7.8131728 10.009872 13.7126677 9.79342899 13.7126677 3.89393398 7.8131728 3.89393398 7.60104076 4.60104077 6.89393398 4.81317281 6.89393398 9.9016505 11.9824116' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    border: 0;
                    width:8px;
                    height:5px;
                    background-size: cover;
               }
            }


            &.signup-error {
              margin-bottom: 0;

              .invalid-feedback.visible {
                width: 286px;
                right: 30px;
                text-align: left;
                padding-left: 0;
                margin-top: 0;
                position: absolute;
                bottom: 150px;
              }
            }
        }

        .form-control {
            font-size: 15px;
            line-height: 20px;
            padding-left: 0 !important;
            padding-right: 0 !important;
            border-radius: 0 !important;

            &.is-invalid {
                color: #ED2929;
                background-image: none;
                box-shadow: inset 0 -0.5px 0 0 #ED2929 !important;
            }
        }

        .invalid-feedback {
            color: #ED2929;
            font-size: 12px;
            line-height: 14px;
            padding-left: 15px;

            &.visible {
                display: inline-block;
                position: absolute;
            }
        }

        input {
            border: 0;

            &:active,
            &:focus {
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                font-size: 15px;
                line-height: 20px;
                color: rgba(14,14,14,0.3);
            }
        }

        .btn-primary {
            box-shadow: none !important;
            &:active {
                background-color: #045772 !important;
            }
        }

        .btn {
            margin-top: 20px;
            width: 100%;
            background-color: #20B4E4;
            height: 48px;
            font-size: 15px;
            border-radius: 0;
            border: 0;
            cursor: pointer;
            font-weight: 600;

            &:disabled
            {
                background:#6DD8FB;
            }

            &:not(:disabled):not(.loading) {

            &:active,
            &:active:focus {
                box-shadow: none;
            }
            }

            &:not(:disabled):not(.loading) {
            &:hover {
                background-color: #0EA8E0;
            }
            }

            &:not(:disabled):not(.loading) {
                &:active {
                    background-color: #009AD5 !important;
                }
            }

            &.loading {
                &:hover {
                background-color: #20B4E4 !important;
                }
            }

            .spinner {
                margin-top: 0;
                background: linear-gradient(to bottom, #fff 10%, rgba(255, 255, 255, 0) 100%);

                &:before {
                    background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #fff 50%);
                }
                &:after {
                    background: #20B4E4;
                }
            }
        }

        .create {
            .prompt {
                margin-bottom: 52px;
            }

            .form-control {
                box-shadow: inset 0 -0.5px 0 0 rgba(14, 14, 14, 0.1);
            }

            .form-group {
                position: relative;
            }

            .invalid-feedback {
                padding-left: 0;
                position:  absolute;
                top: -14px;
            }
        }

        .new-password {
            .prompt {
                margin-bottom: 52px;
            }
            
            .form-control {
                border-bottom: 0.5px solid rgba(14, 14, 14, 0.1);
            }

            .form-group {
                position: relative;
                margin-bottom: 4px;
            }

            .invalid-feedback {
                padding-left: 0;
                position:  absolute;
                top: -20px;
            }
        }
    }
}

