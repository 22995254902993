
.bline {
    animation: expand 1s ease-in-out infinite;
    border-radius: 10px;
    display: inline-block;
    transform-origin: center center;
    margin: 0 3px;
    width: 2px;
    height: 12px;
  }
  
  .bline:nth-child(1) {
    background: #D9F6FF;
  }
  
  .bline:nth-child(2) {
    animation-delay: 180ms;
    background: #D9F6FF;
  }
  
  .bline:nth-child(3) {
    animation-delay: 360ms;
    background: #D9F6FF;
  }
  
  .bline:nth-child(4) {
    animation-delay: 540ms;
    background: #D9F6FF;
  }
  
  @keyframes expand {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(2);
    }
  }
  